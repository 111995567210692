@font-face {
    font-family: aeonikRegular;
    src: url(../../Aeonik-Regular.otf);
}

.body {
    background: #000000;
    background: -webkit-linear-gradient(to right, #434343, #000000);
    background: linear-gradient(to right, #434343, #000000);
    font-family: aeonikRegular !important;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    /* height: 100vh; */
}

.fs-11 {
    font-size: 72px;
    background: linear-gradient(to right, #fdeff9, #ec38bc, #ad36ff, #4939d7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.btn-1 {
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    border-radius: 10px;
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    color: #000000 !important;
}

.input-1 {
    background-color: #000000 !important;
    color: #ffffff !important;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.navbar {
    background: rgba(0, 0, 0, 0.4);
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.cdisplay-1 {
    font-size: calc(1.675rem + 5.1vw);
    font-weight: 600;
    line-height: 1.2;
    background: linear-gradient(to right, #fdeff9, #ec38bc, #ad36ff, #4939d7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (min-width: 1200px) {
    .cdisplay-1 {
        font-size: 6rem;
    }
}

.cbg-img-center {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 600;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-6 {
        font-size: 2.5rem;
    }
}

/* h1, h2, p {
    color: white;
} */













@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (min-width: 1024px) {
    .fs-11 {
        font-size: calc(4.375rem + 1.5vw) !important;
    }

}

@media (max-width: 1024px) {
    .fs-11 {
        font-size: calc(1.375rem + 1.5vw) !important;
    }
}

.sentence {
    color: #222;
    font-size: 30px;
    text-align: left;
}

.slidingVertical {
    display: inline;
    text-indent: 8px;
}

.slidingVertical span {
    animation: topToBottom 12.5s linear infinite 0s;
    -ms-animation: topToBottom 12.5s linear infinite 0s;
    -webkit-animation: topToBottom 12.5s linear infinite 0s;
    color: #00abe9;
    opacity: 0;
    overflow: hidden;
    position: absolute;
}

.slidingVertical span:nth-child(2) {
    animation-delay: 2.5s;
    -ms-animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
}

.slidingVertical span:nth-child(3) {
    animation-delay: 5s;
    -ms-animation-delay: 5s;
    -webkit-animation-delay: 5s;
}

.slidingVertical span:nth-child(4) {
    animation-delay: 7.5s;
    -ms-animation-delay: 7.5s;
    -webkit-animation-delay: 7.5s;
}

.slidingVertical span:nth-child(5) {
    animation-delay: 10s;
    -ms-animation-delay: 10s;
    -webkit-animation-delay: 10s;
}

@keyframes topToBottom {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -moz-transform: translateY(-50px);
    }

    10% {
        opacity: 1;
        -moz-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -moz-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -moz-transform: translateY(50px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes topToBottom {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
    }

    10% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -webkit-transform: translateY(50px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes topToBottom {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -ms-transform: translateY(-50px);
    }

    10% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -ms-transform: translateY(50px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}